export const region = {
    'Seoul' : [
        '서울 전역', '강동구', '강북구', '강서구', '관악구', '광진구', '구로구', '금천구', 
        '노원구', '도봉구', '동대문구', '동작구', '마포구', '서대문구', '서초구', '성동구', '성북구', 
        '송파구', '양천구', '영등포구', '용산구', '은평구', '종로구', '중구', '중랑구'
    ],
    'Incheon' : ['인천광역시 전역'],
    'Gyeonggi' : ['고양시', '과천시', '광명시', '구리시', '군포시', '김포시', '동두천시', '부천시',
        '성남시', '수원시', '시흥시', '안산시', '안성시', '안양시', '양주시', '여주시', '오산시', 
        '용인시', '의왕시', '의정부시', '이천시', '파주시', '평택시', '포천시', '하남시'
    ],
    'Gangwon' : ['춘천시', '원주시', '강릉시','동해시', '태백시', '속초시', '삼척시'],
    'Chungbuk' : ['청주시', '충주시', '제천시'],
    'Chungnam' : ['천안시', '공주시', '보령시', '아산시', '서산시', '논산시', '계릉시', '당진시'],
    'Jeonbuk' : ['전주시', '군산시', '익산시', '정읍시', '남원시', '김제시'],
    'Jeonnam' : ['목포시', '여수시', '순천시', '나주시', '광양시'],
    'Gyeongbuk' : ['포항시', '경주시', '김천시','안동시', '구미시', '영주시', '영천시', '상주시', '문경시', '경산시'],
    'Gyeongnam' : ['창원시', '진주시', '통영시', '사천시', '김해시', '밀양시', '거제시', '양산시'],
    'Jeju' : ['제주시', '서귀포시']
}

export const regionToKorean = {
    'Seoul' : "서울특별시",
    'Incheon' : "인천광역시",
    'Gyeonggi' : "경기도",
    'Gangwon' : "강원도",
    'Chungbuk' : "충청북도",
    'Chungnam' : "충청남도",
    'Jeonbuk' : "전라북도",
    'Jeonnam' : "전라남도",
    'Gyeongbuk' : "경상북도",
    'Gyeongnam' : "경상남도",
    'Jeju' : "제주도"
}