export const personalChefServiceText = `제1조(목적)
  이 약관은 마이요리사(mayo)(정보통신업 사업자)가 운영하는 마이요리사(mayo) 서비스 플랫폼(이하 “mayo”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 mayo과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다. 
  ※「PC통신,무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」
 
제2조(정의)
  ①“mayo”란 마이요리사가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
  ②“이용자”란 “mayo”에 접속하여 이 약관에 따라 “mayo”이 제공하는 서비스를 제공하거나 받는 회원 및 비회원을 말합니다.
  ③“회원”이라 함은 “mayo”에 회원등록을 한 자로서, 계속적으로 “mayo”가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
  ④“비회원”이라 함은 회원에 가입하지 않고 “mayo”가 제공하는 서비스를 이용하는 자를 말합니다.
 
제3조(약관 등의 명시와 설명 및 개정) 
  ①“mayo”는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․팩스번호․전자우편주소, 사업자등록번호,통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 mayo의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
  ②“mayo”는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
  ③“mayo”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
  ④“mayo”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 “mayo”는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 
  ⑤“mayo”가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “mayo”에 송신하여 “mayo”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
  ⑥이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
 
제4조(서비스의 제공 및 변경) 
  ①“mayo”는 다음과 같은 업무를 수행합니다.
    1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
    2. 구매계약이 체결된 재화 또는 용역의 배송
    3. 기타 “mayo”가 정하는 업무
  ②“mayo”는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
  ③“mayo”가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
  ④전항의 경우 “mayo”는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만,“mayo”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
 
제5조(서비스의 중단) 
  ①“mayo”는 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
  ②“mayo”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “mayo”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
  ③사업종목의 전환,사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는“mayo”는 제8조에 정한 방법으로 이용자에게 통지하고 당초 “mayo”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만,“mayo”가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “mayo”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다. 단 마일리지 또는 적립금이 해당되지 않는 경우 이 조항 역시 해당되지 않습니다.
 
제6조(회원가입) 
  ①이용자는 “mayo”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
  ②“mayo”는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
    1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후3년이 경과한 자로서 “mayo”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
    2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
    3. 기타 회원으로 등록하는 것이 “mayo”의 기술상 현저히 지장이 있다고 판단되는 경우
    4. 회원가입 및 전자상거래상의 계약에 관한 서비스는 만14세 이상인자에 한함
  ③회원가입계약의 성립 시기는 “mayo”의 승낙이 회원에게 도달한 시점으로 합니다.
  ④회원은 회원가입 시 등록한사항에 변경이 있는 경우, 상당한 기간 이내에“mayo”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
 
제7조(회원 탈퇴 및 자격 상실 등) 
  ①회원은 “mayo”에 언제든지 탈퇴를 요청할 수 있으며 “mayo”는 즉시 회원탈퇴를 처리합니다.
  ②회원이 다음 각 호의 사유에 해당하는 경우, “mayo”는 회원자격을 제한 및 정지시킬 수 있습니다.
    1. 가입 신청 시에 허위 내용을 등록한 경우
    2. “mayo”를 이용하여 구입한 재화 등의 대금, 기타 “mayo”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
    3. 다른 사람의 “mayo”이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
    4. “mayo”를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
  ③“mayo”가 회원자격을 제한․정지시킨 후, 동일한 행위가2회 이상 반복되거나30일 이내에 그 사유가 시정되지 아니하는 경우 “mayo”는 회원자격을 상실시킬 수 있습니다.
  ④“mayo”는 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
 
제8조(회원에 대한 통지)
  ①“mayo”는 회원에 대한 통지를 하는 경우, 회원이 “mayo”와 미리 약정하여 지정한 전자우편주소로 할 수 있습니다.
  ②“mayo”는 불특정다수 회원에 대한 통지의 경우1주일 이상 “mayo”게시판에 게시함으로서 개별통지에 갈음할 수 있습니다. 다만,회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
 
제9조(구매신청 및 개인정보 제공 동의 등) 
  ①“mayo”의 이용자는 “mayo”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “mayo”는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. 
      1. 재화 등의 검색 및 선택
      2. 받는 사람의 성명,주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
      3. 약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인
      4. 이 약관에 동의하고 위3.호의 사항을 확인하거나 거부하는 표시
         (예, 마우스 클릭)
      5. 재화등의 구매신청 및 이에 관한 확인 또는 “mayo”의 확인에 대한 동의
      6. 결제방법의 선택
  ②“mayo”가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
  ③“mayo”가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.

제10조(계약의 성립)
  ①“mayo”는 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
    1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
    2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
    3. 기타 구매신청에 승낙하는 것이 “mayo”기술상 현저히 지장이 있다고 판단하는 경우
  ②“mayo”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
  ③“mayo”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
 
제11조(지급방법)
“mayo”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “mayo”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
    1. 폰뱅킹, 인터넷뱅킹,메일 뱅킹 등의 각종 계좌이체 
    2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
    3. 온라인무통장입금
 
제12조(수신확인통지․구매신청 변경 및 취소)
  ①“mayo”는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
  ②수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “mayo”는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.
 
제13조(재화 등의 공급)
  ①“mayo”는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터7일 이내에 재화 등을 배송할 수 있도록 주문제작,포장 등 기타의 필요한 조치를 취합니다. 다만, “mayo”가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터3영업일 이내에 조치를 취합니다.  이때 “mayo”는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
  ②“mayo”는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자,수단별 배송기간 등을 명시합니다. 만약 “mayo”가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다.다만 “mayo”가 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다.
 
제14조(환급)
“mayo”는 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 4영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
 
제15조(청약철회 등)
  ①“mayo”와재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만,청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다. 
  ②이용자는 재화 등을 배송 받은 경우 다음 각 호의1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
    1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
    2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
    3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우
    4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
  ③제2항제2호 내지 제4호의 경우에 “mayo”가 사전에 청약철회 등이 제한되는 사실을 이용자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
  ④이용자는 제1항 및 제2항의 규정에 불구하고 재화의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터30일 이내에 청약철회 등을 할 수 있습니다. 하지만 위 내용은 이미 제공된 용역 및 서비스에는 해당되지 않습니다.
 
제16조(청약철회 등의 효과)
  ①“mayo”는이용자로부터 재화 등을 반환받은 경우 4영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 “mayo”는이용자에게 재화등의 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는지연이자율을 곱하여 산정한 지연이자를 지급합니다.
  ②“mayo”는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
  ③청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “mayo”는이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “mayo”가 부담합니다.
  ④이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에“mayo”는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
 
제17조(개인정보보호)
  ①“mayo”는이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다. 
  ②“mayo”는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
  ③“mayo”는이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 
  ④ “mayo”는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
  ⑤ “mayo”가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적,제3자에 대한 정보제공 관련사항(제공받은자,제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
  ⑥ 이용자는 언제든지“mayo”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “mayo”는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “mayo”는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
  ⑦“mayo”는개인정보 보호를위하여 이용자의 개인정보를 취급하는 자를  최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
  ⑧“mayo”또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
  ⑨“mayo”는개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
 
제18조(“mayo”의 의무)
  ①“mayo”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
  ②“mayo”는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
  ③“mayo”가 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
  ④“mayo”는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
 
제19조(회원의ID 및 비밀번호에 대한 의무)
  ①제17조의 경우를 제외한ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
  ②회원은 자신의ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
  ③회원이 자신의ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “mayo”에 통보하고 “mayo”의 안내가 있는 경우에는 그에 따라야 합니다.
 
제20조(이용자의 의무) 
이용자는 다음 행위를 하여서는 안 됩니다.
    1. 신청 또는 변경시 허위 내용의 등록
    2. 타인의 정보 도용
    3. “mayo”에 게시된 정보의 변경
    4. “mayo”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
    5. ”mayo”기타 제3자의 저작권 등 지적재산권에 대한 침해
    6. “mayo”기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
    7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위
 
제21조(저작권의 귀속 및 이용제한)
  ①“mayo”가 작성한 저작물 및 기타 지식재산에 대한 저작권, 상표,특허, 영업비밀 및 기타 지적재산권은 “mayo”에 귀속합니다.
  ②이용자는 “mayo”를 이용함으로써 얻은 정보 중 “mayo”에게 지적재산권이 귀속된 정보를 “mayo”의 사전 승낙 없이 복제, 송신,출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
  ③“mayo”는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
  ④“mayo”는 이용자가 이 조항을 침해할 경우 그에 대한 손해배상을 청구할 수 있습니다.
 
제22조(분쟁해결)
  ①“mayo”는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.
  ②“mayo”는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
  ③“mayo”와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
 
제23조(재판권 및 준거법)
  ①“mayo”와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고,주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만,제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
  ②“mayo”와 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
 
*부칙
1.이 약관은2019년05월13일부터 시행됩니다.
2.이 약관은 이용자에게 제공되는 서비스 조건 및 안내문의 내용과 함께 이용자와 마이요리사(mayo) 사이의 계약 내용을 형성합니다. 만약 이 약관의 내용과 개별 이용자에게 제공된 서비스 조건 및 안내문의 내용 사이에 차이가 있을 경우 개별 이용자에게 제공된 서비스 조건 및 안내문의 내용이 우선합니다.
3.mayo 서비스 안내 마이요리사(mayo)(이하 “회사’)는 아래 내용에 따라 귀하에게 mayo 서비스를 제공해 드립니다.
1. 서비스 고객께서 주문하신 mayo 서비스는 전문 셰프님이 고객의 집을 방문하여 고객이 선택하신 메뉴를 직접 요리하여 서빙을 하는 재택 셰프 서비스(이하 ‘셰프 서비스’)입니다.
2. 계약 체결 이 주문 확인 이메일을 받으신 이후 3 영업일 이내에 해당 서비스 가격 전액을 결제하셔야만 계약이 성립되어 셰프 서비스가 제공됩니다. 3 영업일 이내에 결제하지 않을 경우 주문은 자동 취소됩니다. 결제는 계좌이체 또는 신용카드로만 할 수 있습니다.
3. 취소 및 환불 취소와 환불은 다음과 같은 조건에 따릅니다. 인원수 변경 등 부분취소도 동일한 환불 규정이 적용되며 카드결제 취소 수수료가 부과될 수 있습니다.
① 취소는 mayo 웹사이트에서 공지하는 방법에 따라 서면으로만 가능합니다.
② 고객이 서비스 당일로부터 최소 14 일 이전까지 취소하는 경우 결제한 금액의 100%를 환불합니다.
③ 고객이 서비스 당일로부터 13 일에서 7 일까지 취소하는 경우 결제한 금액의 40%를 환불합니다
④ 고객이 서비스 당일로부터 6 일에서 3 일까지 취소하는 경우 결제한 금액의 30%를 환불합니다
⑤ 고객이 서비스 당일로부터 2 일 전까지 취소하는 경우 결제한 금액의 10%를 환불합니다.
⑥ 회사는 언제든 서비스를 취소할 수 있고 그럴 경우 고객에게 결제한 금액을 100%롤 환불해 드립니다.
4.서비스 내용과 고객의 의무
① 서비스에 포함되는 용역:
서비스(이하 ‘셰프 서비스”에는 식재료 구매 및 손질, 요리 준비 및 조리 그리고 조리과정에서의 쓰인 조리기구 세척까지 포함됩니다.
② 서비스에 제외되는 용역:
셰프 서비스에 식기 및 수저 제공, 식기세척, 기타 주방 정리정돈, 쓰레기 처리 등은 포함되지 않습니다.
5.서비스 당일 유의사항
① 서비스 당일 셰프가 시작 시간
60~90 분전에 고객의 집에 도착하여 준비를 시작할 것입니다. 메뉴에 따라서 미리 준비되어 가는 재료도 있을 것입니다.
② 고객은 당일 셰프가 요리를 할 수 있는 청결하고 안전한 환경 및 셰프가 조리를 하기 위하여 필요한 기본 공간과 도구를 제공해야 합니다.  
③ 요리하는 공간은 뜨거운 불과 기름, 위험한 도구가 쓰여지는 위험한 공간이므로 고객은 셰프가 요리하는 동안 주방에 어린이나 반려동물은 출입하지 않도록 통제할 의무가 있습니다.
④ 고객은 셰프가 가져온 재료나 도구를 셰프의 허락없이 열거나 손을 대면 안됩니다.
⑤ 각 가정에 요리에 필요한 기본적인 재료, 예를 들어 소금, 후추, 설탕, 간장, 고추가루, 식용유, 등은 사전 협의가 없는 한 고객의 가정에 있는 것을 사용합니다.
⑥ 서비스는 사전에 고객이 선택한 메뉴를 요리하여 제공하는 것입니다. 위생관리상 여분의 식재료가 있더라고 남겨두지 않습니다.
⑦ 조리한 음식의 여분은 폐기처리하는 것이 원칙입니다. 만약 고객이 남은 음식을 만약 포장하여 냉장고에 보관할 경우 셰프도 회사도 그에 대하여 어떤 책임도 지지 않습니다.
⑧셰프의 실수나 잘못으로 고객의 일원이 다치거나 고객의 재산이나 기물에 파손되었을 경우 셰프는 그에 대한 책임을 고객에게 직접 지게 됩니다. 이 경우 회사는 고객에게 책임이 없습니다.
⑨고객의 실수나 잘못으로 셰프가 다치거나 셰프의 재산 또는 기물이 파손되었을 경우 고객은 셰프에게 그에 대한 책임을 직접 지게 됩니다.
⑩ 만약 서비스 제공 당일 고객의 집이 어떤 이유로든 접근불가하거나  안전하지 않기 때문에 서비스를 제공하지 못한 경우 고객은 서비스 비용의 전액을 부담해야 합니다.
⑪ 고객은 셰프의 전화번호 등 개인정보를 취득할 수 없습니다. 만약 mayo 서비스를 이용한 이후 고객이 mayo 서비스로 알게된 셰프와 mayo 서비스를 통하지 않고 직접 거래하는 경우 고객은 셰프와 회사 사이의 계약을 침해하는 행위를 유인하게 됩니다. 이에 대하여 회사는 고객을 상대로 법적 책임을 묻고 손해배상을 청구할 권리가 있습니다.
⑫ 만약 서비스 당일 셰프가 어떤 이유로든 서비스를 제공하지 못할 경우 회사는 다른 셰프를 통해 동일한 메뉴를 제공하거나 고객에게 결제한 금액 전액을 환불해 드립니다.`;

export const PurchaseServiceText = `제1조(서비스의 제공)
    “mayo”는 이용자의 요청에 따라 다음 각 호의 업무를 처리할 수 있습니다.
    ①이용자가 구매·대여 신청한 재화 등의 탐색 및 구매·대여 대행
    ②이용자가 구매·대여 신청한 재화 등에 대한 매매사업자·대여사업자와의 사이의 구매·대여 계약의 체결 및 구매·대여 대금의 지급
    ③기타 “mayo”가 정하는 업무
 
제2조(구매·대여 대행 계약의 성립)
    ①“mayo”는 이용자의 구매·대여 대행 신청이 있으면 수신확인통지를 합니다. 수신확인통지에는 신청내역, 금액, 수령 주소 등의 신청 정보와 구매·대여 대행 신청의 정정, 취소 등에 관한 정보 등을 포함하여야 합니다.
    ②구매·대여 대행 계약은 이용자가 신청하면 “mayo”가 구매·대여 대행 가능 여부를 통지하고 구매·대여 신청한 재화 등의 대금을 제시하여 이용자가 대금을 지급한 때에 성립됩니다.
    ③수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우 지체 없이 “mayo”에 구매·대여 대행 신청 변경 또는 취소를 요청할 수 있으며, “mayo”는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지급한 경우에는 제5조의 청약철회 등에 관한 규정에 따릅니다.
 
제3조(지급방법)
    ①이용자는 약정한 구매·대여 대행 대금을 선납하여야 합니다.
    ②이용자의 요구로 인하여 구매·대여 대행 대금 외의 추가비용이 발생한 경우에는 이용자는 그 추가비용을 부담하여야 합니다.
    ③이용자는 대여기간을 초과하여 대여품을 사용한 경우에는 대여품 반환 시 추가로 대여요금 및 손해배상금을 지급하여야 합니다.
 
제4조(환급)
    “mayo”는 이용자가 구매·대여 대행 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 4영업일 이내에 환급하거나 환급에 필요한 조치를 취하며, 이에 대하여 발생하는 직간접적인 모든 책임을 지지 아니합니다.
 
제5조(청약철회 등)
    ①이용자가 구매·대여 대행 신청을 한 후 “mayo”가 매매사업자·대여사업자와 구매·대여 대행을 신청받은 재화 등의 구매·대여 계약을 체결하기 전, 이용자가 구매·대여 대행계약의 청약철회 등을 한 경우에는, “mayo”는 이용자가 기납부한 구매·대여 대행 대금에서 10%의 취소수수료를 차감한 후 잔액을 환급합니다.
    ②“mayo”가 매매사업자·대여사업자와 구매·대여 대행을 신청 받은 재화 등의 구매·대여 계약을 체결한 이후에는 이용자는 구매·대여 대행계약의 청약철회 등을 할 수 없습니다.
    ③제2항의 경우에 이용자는 “mayo”에 환급을 청구하지 못합니다. 다만, 이용자가 “mayo”에 청약철회 등의 의사를 밝힌 경우로서 매매사업자·대여사업자가 반품 및 환불에 동의하는 경우에 한하여 “mayo”는 이용자의 비용 부담으로 해당 재화 등을 매매사업자·대여사업자에게 반품하고 매매사업자·대여사업자가 환불한 금액에서 취소수수료(이용자가 “mayo”에 기납부한 구매·대여 대행 대금의 10%)를 차감한 후, 환불 금액이 있는 경우, 이용자에게 환급할 수 있습니다.
    ④제2항의 경우, 매매사업자·대여사업자가 해당 재화 등의 반품 및 환불에 동의하지 아니하면, “mayo”는 이용자에게 4영업일의 기간을 정하여 그 기간 내에 해당 재화 등의 처분에 관한 지시를 최고합니다.
 
제6조(손해배상)
    ①이용자가 부정확한 정보를 기입하여 손해가 발생한 경우에는 “mayo”는 배상책임을 지지 아니합니다.
    ②재화 등에 성능·기능상의 하자가 있거나 운송 도중 재화의 분실, 파손 등이 발생하였을 경우, “mayo”는 선량한 관리자 주의로써 매매사업자·대여사업자에게 반품, 교환, 환불 요구 등의 조치를 취해야 할 의무가 있습니다.
    ③“mayo”는 제공되는 구매·대여 대행 업무와 관련하여 이용자에게 어떠한 손해가 발생하더라도 동 손해가 “mayo”의 고의 또는 중대한 과실로 인한 경우를 제외하고 이에 대하여 일체의 책임을 부담하지 아니합니다.
    ④대여품 대여기간 내에 이용자의 책임 있는 사유로 인해 대여품이 멸실, 훼손, 분실, 완전파손시 이용자는 “mayo”에 대여품의 수리비용 또는 대체 구입비용을 지급하여야 합니다.
 
제7조(대여품의 사용, 보관 및 유지, 관리 의무)
    ①이용자는 대여품의 선량한 관리자로서 의무를 다하여 본래의 용도에 따라 사용, 보관, 관리하여야 합니다.
    ②대여품의 사용, 보관과 관련하여 제3자에게 재산상, 신체상 손해가 발생한 경우 이용자의 책임과 비용으로 손해배상 처리하여야 하며, “mayo”에게 손해배상 책임이 미치지 않도록 합니다.
 
제8조(책임의 특별소멸사유와 시효)
    운송물의 누락 또는 파손에 대한 “mayo”의 손해배상책임은 이용자가 운송물이 배송 완료된 날로부터 24시간 이내에 그 누락 또는 파손에 대한 사실을 “mayo”에 통지하지 아니하면 소멸합니다.
 
제9조(불가항력)
    “mayo”는 천재지변, 전쟁, 내란, 사변, 폭동, 소요 등 기타 불가항력 사유로 인하여 의무 불이행에 대하여 책임지지 아니합니다.`;

export const PrivacyPolicyText = `마이요리사(mayo)는 개인 정보 보호법 제 30 조에 따라 정보 주체(고객)의 개인 정보를 보호하고 이와 관련 한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리 지침을 수립․공개합니다.


    1. 개인 정보의 처리 목적 마이요리사(mayo)는 다음의 목적을 위하여 개인 정보를 처리하고있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
        고객 가입 의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별․인증, 회원 자격 유지․관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급․배송 등
     
    2. 개인 정보의 처리 및 보유 기간
    ① 마이요리사(mayo)는 정보 주체로부터 개인 정보를 수집할 때 동의 받은 개인 정보 보유․이용 기간 또는 법령에 따른 개인 정보 보유․이용 기간 내에서 개인 정보를 처리․보유합니다.
    ②구체적인 개인 정보 처리 및 보유기간은 다음과 같습니다.
        - 고객 가입 및 관리: 서비스 이용 계약 또는 회원가입 해지 시 까지, 다만 채권․채무 관계 잔존시에는 해당 채권․채무 관계 정산시까지
        - 전자상거래에서의 계약․청약 철회, 대금 결제, 재화 등 공급기록: 5년
     
    3. 개인 정보의 제3자 제공 마이요리사(mayo)는 정보 주체의 별도 동의, 법률의 특별한 규정 등 개인정보보호법 제17조에 해당하는 경우 외에는 개인 정보를 제3자에게 제공하지 않습니다.
   
    4. 개인 정보 처리의 위탁 
        ①마이요리사(mayo)는 원활한 개인 정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 외부에 위탁하고 있습니다.
            결제서비스: (주)토스페이먼츠
        ②마이요리사(mayo)는 위탁 계약 체결시 개인 정보 보호법 제25조에 따라 위탁 업무 수행 목적 외 개인정보 처리금지, 재위탁 제한, 수탁자에 대한 관리․감독, 책임에 관한 사항을 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
    
    5. 정보 주체와 법정대리인의 권리․ 의무 및 행사 방법 정보 주체는 마이요리사(mayo)에 대해 언제든지 다음 각호의 개인정보 보호관련권리를 행사 할 수 있습니다.
        1. 개인정보 열람 요구
        2. 개인정보에 오류등이 있을경우 정정 요구
        3. 삭제 요구
        4. 처리정지 요구
     
    6. 처리하는 개인정보 항목 마이요리사(mayo)는 다음의 개인정보항목을 처리하고있습니다.
        – 성명, 생년월일, 주소, 전화번호, 휴대전화번호, 성별, 이메일주소, 신용카드번호, 은행계좌번호등결제정보
     
    7. 개인정보의 파기
        ①마이요리사(mayo)는 개인정보 보유기간의경과, 처리 목적 달성 등 개인정보가 불 필요하게 되었을때에는 지체없이 해당 개인정보를 파기합니다.
        ②마이요리사(mayo)는 다음의 방법으로 개인정보를 파기합니다.
            – 전자적파일: 파일삭제 및 디스크 등 저장매체 포맷
            – 수기(手記) 문서: 분쇄하거나 소각     마이요리사(mayo)는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
            – 관리적 조치 : 내부관리계획수립․시행, 직원․종업원등에대한정기적교육
            – 기술적 조치 : 개인정보처리시스템(또는개인정보가저장된컴퓨터)의비밀번호설정등접근권한관리, 백신소프트웨어등보안프로그램설치, 개인정보가저장된파일의암호화
            – 물리적조치: 개인정보가저장․보관된장소의시건, 출입통제등
     
    8. 개인정보 보호책임자 마이요리사(mayo)는 개인정보처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        ▶개인정보 보호책임자
        성명: 남재호         직책: 대표
        연락처: 010-5697-5589,  mayoseoul@gmail.com

    9. 개인 정보 처리 방침 시행 및 변경 이 개인정보 처리방침은 2024.09.08부터 적용됩니다. 본 개인정보 처리방침을 개정할 경우에는 최소 7일전에 홈페이지 또는 이메일을 통해 변경 및 내용 등을 공지하도록 하겠습니다. 다만 이용자의 소중한 권리 또는 중요한 내용에 변경이 발생하는 경우 시행일로부터 최소 30일 전에 공지하도록 하겠습니다.
`;
