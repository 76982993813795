import React from 'react';
import { UserEditInfo } from '../customer/CustomerEditInfo';

export const ChefEditInfo = () => {
  return (
    <>
    <UserEditInfo type={'chef'}/>
    </>
  )
}
